import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import BlockList from "../components/block-list"
import RichText from "../components/rich-text"
import Article from "../components/article"
import ContentFooter from "../components/content-footer"
import SecondaryText from "../components/secondary-text"
import { getDescription, getMetaImageSrc, getTitle } from "../common/utils"
import * as styles from "./blog-post.module.css"
import useLinksTo from "../hooks/use-links-to"

const renderAuthor = author => (
  <>
    By {author.name}
    {author.title && (
      <>
        , <SecondaryText>{author.title}</SecondaryText>
      </>
    )}
  </>
)

const readingTimeString = readingTime =>
  readingTime ? `${readingTime} min` : null

const renderSubtitle = (post, author) => (
  <>
    {author && (
      <span className={styles.subtitleItem}>{renderAuthor(author)}</span>
    )}
    {post.publishedAt && (
      <span className={styles.subtitleItem}>{post.publishedAt}</span>
    )}
    {post.readingTime && (
      <span className={styles.subtitleItem}>
        Read time {readingTimeString(post.readingTime)}
      </span>
    )}
  </>
)

export const Head = ({ data: { post }, location }) => (
  <SEO
    title={getTitle(post)}
    description={getDescription(post)}
    metaImage={getMetaImageSrc(post)}
    metaTwitterCardType={post.twitterSharePreviewType}
    readingTime={readingTimeString(post.readingTime)}
    pathname={location?.pathname}
  />
)

const BlogPost = ({ data: { post, next }, pageContext }) => {
  const { author } = post
  const { getPostLinkTo } = useLinksTo()

  return (
    <Layout
      pageContext={pageContext}
      footer={
        <ContentFooter
          title={next.title}
          subtitle="Go to next post"
          image={next.image}
          to={getPostLinkTo(next.slug)}
        />
      }
    >
      <Header
        title={post.title}
        subtitle={renderSubtitle(post, author)}
        image={post.video || post.image}
      />
      {post.before && <BlockList data={post.before} />}
      {post.content && (
        <Article>
          <RichText content={post.content} />
        </Article>
      )}
      {post.after && <BlockList data={post.after} />}
    </Layout>
  )
}

export const query = graphql`
  query ($contentfulId: String!, $nextContentfulId: String!) {
    post: contentfulBlogPost(contentful_id: { eq: $contentfulId }) {
      title
      publishedAt(formatString: "MMM D, YYYY")
      image {
        title
        gatsbyImageData(layout: FULL_WIDTH)
      }
      metaTitle
      metaDescription {
        metaDescription
      }
      metaImage {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      twitterSharePreviewType
      video {
        file {
          url
        }
      }
      author {
        name
        title
      }
      readingTime
      content {
        raw
        references {
          ... on ContentfulAsset {
            # __typename and contentful_id are required to resolve the references
            __typename
            contentful_id
            title
            gatsbyImageData(layout: FULL_WIDTH)
            file {
              contentType
            }
          }
          ... on ContentfulHubSpotFormBlock {
            ...HubSpotFormBlock
          }
          ... on ContentfulHyperlinkButtonBlock {
            ...HyperlinkButtonBlock
          }
          ... on ContentfulVideoEmbedBlock {
            ...VideoEmbedBlock
          }
        }
      }
      before {
        __typename
        ... on Node {
          ... on ContentfulHeaderBlock {
            ...HeaderBlock
          }
          ... on ContentfulSectionBlock {
            ...SectionBlock
          }
          ... on ContentfulQuoteBlock {
            ...QuoteBlock
          }
          ... on ContentfulImageBlock {
            ...ImageBlock
          }
          ... on ContentfulOfficeBlock {
            ...OfficeBlock
          }
          ... on ContentfulStatisticsBlock {
            ...StatisticsBlock
          }
          ... on ContentfulLogosBlock {
            ...LogosBlock
          }
          ... on ContentfulImageLinkListBlock {
            ...ImageLinkListBlock
          }
          ... on ContentfulContactBlock {
            ...ContactBlock
          }
          ... on ContentfulHubSpotFormBlock {
            ...HubSpotFormBlock
          }
          ... on ContentfulComponentBlock {
            ...ComponentBlock
          }
          ... on ContentfulHyperlinkButtonBlock {
            ...HyperlinkButtonBlock
          }
          ... on ContentfulTableBlock {
            ...TableBlock
          }
          ... on ContentfulHubSpotMeetingBlock {
            ...HubSpotMeetingBlock
          }
          ... on ContentfulVideoEmbedBlock {
            ...VideoEmbedBlock
          }
          ... on ContentfulCodeSnippetBlock {
            ...CodeSnippetBlock
          }
        }
      }
      after {
        __typename
        ... on Node {
          ... on ContentfulHeaderBlock {
            ...HeaderBlock
          }
          ... on ContentfulSectionBlock {
            ...SectionBlock
          }
          ... on ContentfulQuoteBlock {
            ...QuoteBlock
          }
          ... on ContentfulImageBlock {
            ...ImageBlock
          }
          ... on ContentfulOfficeBlock {
            ...OfficeBlock
          }
          ... on ContentfulStatisticsBlock {
            ...StatisticsBlock
          }
          ... on ContentfulLogosBlock {
            ...LogosBlock
          }
          ... on ContentfulImageLinkListBlock {
            ...ImageLinkListBlock
          }
          ... on ContentfulContactBlock {
            ...ContactBlock
          }
          ... on ContentfulHubSpotFormBlock {
            ...HubSpotFormBlock
          }
          ... on ContentfulComponentBlock {
            ...ComponentBlock
          }
          ... on ContentfulHyperlinkButtonBlock {
            ...HyperlinkButtonBlock
          }
          ... on ContentfulTableBlock {
            ...TableBlock
          }
          ... on ContentfulHubSpotMeetingBlock {
            ...HubSpotMeetingBlock
          }
          ... on ContentfulVideoEmbedBlock {
            ...VideoEmbedBlock
          }
          ... on ContentfulCodeSnippetBlock {
            ...CodeSnippetBlock
          }
        }
      }
    }
    next: contentfulBlogPost(contentful_id: { eq: $nextContentfulId }) {
      title
      slug
      image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`

export default BlogPost
